import React, {Component} from 'react';

class Page404 extends Component {
    render() {
        return (
            <div id="page-404-body">
                <div id="page-404" title="404">404</div>
            </div>
        );
    }
}

export default Page404;
